import { h, render, Component, Fragment } from "preact";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "preact/hooks";
import moment from "moment-timezone";
import PlusCircleIcon from "@heroicons/react/solid/PlusCircleIcon";
import TrashIcon from "@heroicons/react/outline/TrashIcon";
import { fuzzySearch } from "react-select-search";
import SelectSearch from "react-select-search";

import Clock from "./Clock";
import { useClockSettings } from "../../hooks/useClockSettings";
import Modal from "../Modal";

const TIMEZONE_NAMES = moment.tz.names();

const getTimezoneInfo = (timezone) => {
  const place = timezone.split("/");
  if (place.length === 1) {
    return place[0].replace("_", " ");
  }

  return place[1].replace("_", " ");
};

const ClockSettings = ({ onRemove, settings }) => {
  const { timezone, clocks } = settings;

  return (
    <>
      <div className="flex flex-col">
        {clocks.map((clock, i) => (
          <div className="flex justify-between mb-1 p-2 w-full rounded-sm border-blue-50">
            <span>{clock.place}</span>
            <button
              className="py-2 px-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
              onClick={(e) => onRemove(e, clock.timezone)}
            >
              <TrashIcon className="w-4 h-4 text-white" />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

// widget for world clock
const WorldClockWidget = ({ data }) => {
  const [settings, setSettings] = useClockSettings();
  const { clocks } = settings;
  const widgetRef = useRef();
  const [showSetting, setShowSetting] = useState(false);

  const onAddButtonClick = useCallback(() => {
    setShowSetting((s) => !s);
  }, []);
  const [formData, setFormData] = useState({
    title: "",
    timezone: "",
  });

  const onRemove = useCallback(
    (e, timezone) => {
      const _settings = { ...settings };
      const clockToRemove = _settings.clocks.find(
        (c) => c.timezone == timezone
      );
      if (!clockToRemove) return;

      _settings.clocks.splice(_settings.clocks.indexOf(clockToRemove), 1);

      setSettings(_settings);
    },
    [settings, setSettings]
  );

  const onAdd = useCallback(
    (e) => {
      if (formData.timezone.length === 0) {
        alert("Please select a place");
        return;
      }
      const _settings = { ...settings };
      _settings.clocks.push({
        place: getTimezoneInfo(formData.timezone),
        timezone: formData.timezone,
        title: getTimezoneInfo(formData.timezone),
      });

      setSettings(_settings);
      setFormData({
        title: "",
        timezone: "",
      });
    },
    [settings, setSettings, formData.timezone]
  );
  const timezones = useMemo(() => {
    return TIMEZONE_NAMES.map((t) => ({ name: t, value: t }));
  }, []);
  return (
    <>
      <div
        class="clock-widget-container flex flex-col group h-52 relative"
        style="width:30rem"
      >
        <div
          className="clock-widget flex scrollable scrollable-x relative"
          ref={widgetRef}
        >
          {clocks.map((t) => (
            <div class="h-64 w-40">
              <Clock
                title={t.place}
                timezone={t.timezone}
                onRemove={onRemove}
              />
            </div>
          ))}
        </div>
        <button
          class="group-hover:opacity-100 opacity-0 absolute -top-11 right-1.5"
          onClick={onAddButtonClick}
        >
          <PlusCircleIcon class="h-10 w-10 text-white shadow-2xl drop-shadow-md" />
        </button>
      </div>

      <Modal
        onSubmit={() => {
          setShowSetting(false);
        }}
        onClose={() => setShowSetting(false)}
        header={() => <span class="text-xl ">World time</span>}
        showModal={showSetting}
        footer={<></>}
      >
        <div class="relative h-64 w-full left-0 bottom-0">
          <div className="h-5/6 scrollable scrollable-y">
            <ClockSettings
              onRemove={onRemove}
              settings={settings}
            ></ClockSettings>
          </div>
          <div class="h-1/6">
            {/* <label htmlFor="clock_timezone" className="text-sm">
                  Timezone
                </label> */}
            <div class="absolute bottom-0 flex items-center mr-3 mt-4 justify-between w-full">
              <SelectSearch
                options={timezones}
                search
                value={formData.timezone}
                onChange={(e) => {
                  formData.title = formData.timezone = e;
                  setFormData(formData);
                }}
                filterOptions={fuzzySearch}
                emptyMessage="Not found"
                placeholder="Select timezone to add"
              />
              <button
                className="flex items-center justify-between py-1.5 px-4 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                onClick={onAdd}
              >
                <span>Add</span>
              </button>
            </div>
            <div>
              {/* <label htmlFor="clock_title">Name</label>

                <div class="relative ">
                  <input
                    type="text"
                    id="clock_title"
                    class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    placeholder="Name"
                    value={formData.title}
                  />
                </div> */}
            </div>
            {/* <div>
              <button onClick={onSubmit}></button>
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WorldClockWidget;
