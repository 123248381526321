import { h, render, Component } from "preact";

const FooterLine = ({ }) => {
  return (
    <div>
        <div className="footing">
            <div className="footer-links">
                <a className="hover:text-green-600" target="_blank" href="https://kryopage.com/privacy">Privacy policy</a>
                <div>|</div>
                <a className="hover:text-green-600" target="_blank" href="https://kryopage.com/terms">Terms and conditions</a>
            </div>
            <div>
                All the rights reserved to © Moblead LLC
            </div>
        </div>
    </div>
  );
};

export default FooterLine;
