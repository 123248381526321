import { h, Component, Fragment } from "preact";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "preact/hooks";
import moment from "moment-timezone";
import CloseIcon from "../Icons/CloseIcon";

const formatTime = (time) => {
  return time < 10 ? `0${time}` : time;
};

const Clock = ({ title, timezone, onRemove }) => {
  const [loading, setLoading] = useState(true);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const clockInterval = useRef();

  const handleDate = useCallback(() => {
    const date = moment.tz(timezone);
    let hours = formatTime(date.hours());
    let minutes = formatTime(date.minutes());
    let seconds = formatTime(date.seconds());

    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }, [timezone]);

  const secondsStyle = {
    transform: `rotate(${seconds * 6}deg)`,
  };
  const minutesStyle = useMemo(
    () => ({
      transform: `rotate(${minutes * 6}deg)`,
    }),
    [minutes]
  );
  const hoursStyle = useMemo(
    () => ({
      transform: `rotate(${hours * 30}deg)`,
    }),
    [hours]
  );

  const onRemoveClick = useCallback(
    (e) => {
      onRemove(e, timezone);
    },
    [timezone]
  );
  useEffect(() => {
    clockInterval.current = setInterval(handleDate, 1000);
    setLoading(false);
    return () => {
      clearInterval(clockInterval.current);
    };
  }, []);

  const closeIcon = useMemo(
    () => <CloseIcon className="text-white w-4 h-4" />,
    []
  );
  return (
    <>
      {loading || (
        <div class="clock p-2 rounded-3xl ml-4 max-w- relative">
          <span class="text-center justify-self-center font-semibold text-sm mb-2 text-white">
            {title}
          </span>
          <div class="analog-clock h-28 w-28 mx-2">
            <div className="hours-container" style={hoursStyle}>
              <div className="hours" />
            </div>
            <div className="minutes-container" style={minutesStyle}>
              <div className="minutes" />
            </div>
            <div className="seconds-container" style={secondsStyle}>
              <div className="seconds" />
            </div>
          </div>
          <div class="digital-clock text-sm text-white">
            {hours}:{minutes}:{seconds}
          </div>
          <button
            className="absolute right-3 text-white top-3"
            onClick={onRemoveClick}
          >
            {closeIcon}
          </button>
        </div>
      )}
    </>
  );
};

export default Clock;
