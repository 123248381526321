import { h, render, Component } from "preact";
import { searchEngines } from "../helpers";

const SearchList = (props) => {
  return (
    <div
      class="absolute w-full top-full left-10 bg-blue-100 rounded-lg p-3 z-10 search-list"
      id="searchList"
    >
      <div class=" flex flex-wrap relative items" id="searchItems">
        {searchEngines.map((engine) => (
          <div
            onClick={() => {
              props.setSearchLogo(engine.icon);
              localStorage.setItem(
                "searchEngine",
                JSON.stringify(engine)
              );
            }}
            class=" cursor-pointer m-1 flex flex-col justify-between items-center px-3 search-item"
          >
            <img class="w-10" src={engine.icon} alt={engine.name} />
            <label class="mt-2">{engine.name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchList;
