import { h, render, Component } from "preact";
import { useState } from "preact/hooks";
import SettingsIcon from "../images/settings.png";
import LinksIcon from "../images/weblinks.png";
import BackgroundImg from "../images/bing-wallpaper.jpeg";
import API from "../js/ApiService";

const Sidebar = (props) => {
  const [wallSetting, setWallSetting] = useState(
    localStorage.getItem("wallpaperSetting") || "random"
  );
  const selectedFeed = localStorage.getItem("feed");
  const onTabClick = (event) => {
    var actives = document.querySelectorAll(".tab__item--active");

    var activePanes = document.querySelectorAll(".tab__pane--active");
    // deactivate existing active tab and panel
    for (var i = 0; i < actives.length; i++) {
      actives[i].classList.remove("tab__item--active");
      activePanes[i].classList.remove("tab__pane--active");
    }
    let target = event.target;
    while (true) {
      if (target.tagName === "A") {
        break;
      }
      target = target.parentElement;
      if (target == event.currentTarget) {
        alert("Not found");
        break;
      }
    }

    // activate new tab and panel
    target.parentElement.classList.add("tab__item--active");
    document
      .getElementById(target.href.split("#")[1])
      .classList.add("tab__pane--active");
  };

  const saveGeneralSetting = (e, label) => {
    localStorage.setItem(label, e.target.checked);
  };

  const changeWallpaper = async (type) => {
    switch (type) {
      case "bing":
        document
          .getElementById("selectWallpaper")
          .parentElement.setAttribute("disabled", true);
        document.getElementById("selectWallpaper").disabled = true;
        document
          .getElementById("selectWallpaperFolder")
          .parentElement.setAttribute("disabled", "disabled");
        document.getElementById("selectWallpaperFolder").disabled = true;
        const img = await API.get("wallpaper/bing");
        const imgUrl = img?.data?.images[0]?.url;
        localStorage.setItem("wallpaper", `https://bing.com${imgUrl}`);
        localStorage.setItem("wallpaperSetting", "bing");
        document.body.style.backgroundImage = `url(https://bing.com${imgUrl})`;
        setWallSetting("bing");
        break;
      case "local":
        document
          .getElementById("selectWallpaper")
          .parentElement.removeAttribute("disabled");
        document.getElementById("selectWallpaper").disabled = false;
        document
          .getElementById("selectWallpaperFolder")
          .parentElement.setAttribute("disabled", "disabled");
        document.getElementById("selectWallpaperFolder").disabled = true;
        localStorage.setItem("wallpaperSetting", "local");
        break;
      case "random":
        localStorage.setItem("wallpaperSetting", "random");
        document
          .getElementById("selectWallpaper")
          .parentElement.setAttribute("disabled", true);
        document.getElementById("selectWallpaper").disabled = true;
        document
          .getElementById("selectWallpaperFolder")
          .parentElement.setAttribute("disabled", "disabled");
        document.getElementById("selectWallpaperFolder").disabled = true;
        break;
      // case 'folder':
      //   document
      //     .getElementById('selectWallpaperFolder')
      //     .parentElement.removeAttribute('disabled');
      //   document.getElementById('selectWallpaperFolder').disabled = false;
      //   document
      //     .getElementById('selectWallpaper')
      //     .parentElement.setAttribute('disabled', 'disbaled');
      //   document.getElementById('selectWallpaper').disabled = true;
      //   localStorage.setItem('wallpaperSetting', 'folder');
      //   break;
      default:
        break;
    }
  };
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    function () {
      localStorage.setItem("wallpaper", reader.result);
      document.body.style.backgroundImage = `url(${reader.result})`;
    },
    false
  );
  const loadLocalWallpaper = (e) => {
    const image = e.target.files[0];

    if (image) reader.readAsDataURL(image);
  };

  // let i = 0;
  // let files;
  // const backgroundChanger = () => {
  //   if (i >= files.length) {
  //     i = 0;
  //   }

  //   freader.readAsDataURL(files[i++]);
  // };

  // const loadFolderWallpaper = async (e) => {
  //   localStorage.setItem('wallpaperFolder', JSON.stringify([]));
  //   files = await e.target.files;
  //   setInterval(backgroundChanger, 100000);
  // };

  return (
    <div class="be-container text-white" data-effect="be-effect-slide">
      <input
        type="checkbox"
        id="be-toggle-left"
        class="be-toggle"
        data-toggle="left"
      />
      <label
        class="be-toggle-label"
        for="be-toggle-left"
        data-toggle-label="left"
      >
        <span class="be-toggle-icon"></span>
      </label>

      <nav class="be-drawer" data-position="left">
        <div class="tabs">
          <ul id="nav-tab" onClick={onTabClick} class="tab__list">
            <li class="tab__item tab__item--active">
              <a class="tab__link text-lg" href="#general">
                <img class="w-12" src={SettingsIcon} alt="settings" />
              </a>
            </li>
            <li class="tab__item">
              <a class="tab__link text-lg" href="#add">
                <img class=" w-12" src={LinksIcon} alt="weblinks" />
              </a>
            </li>
          </ul>
        </div>
        <div class="tab__content">
          <div class="tab__pane tab__pane--active" id="general">
            <div class="row collapse">
              <h2 class=" text-2xl">Settings</h2>
              <div class="large-12 columns">
                <h4 class="text-lg">General</h4>
                <ul>
                  <li class="toggle__container">
                    <label class="toggle__text-label" for="input">
                      Open <b>Search</b> results in a new tab
                    </label>
                    <input
                      class="toggle__input"
                      id="input"
                      onChange={(e) => saveGeneralSetting(e, "searchSetting")}
                      defaultChecked={
                        localStorage.searchSetting === "true" ? true : false
                      }
                      type="checkbox"
                    />
                    <label class="toggle" for="input"></label>
                  </li>
                  <li class="toggle__container">
                    <label class="toggle__text-label" for="website">
                      Open <b>Website</b> in a new tab
                    </label>
                    <input
                      class="toggle__input"
                      id="website"
                      onChange={(e) => saveGeneralSetting(e, "websiteSetting")}
                      defaultChecked={
                        localStorage.websiteSetting === "true" ? true : false
                      }
                      type="checkbox"
                    />
                    <label class="toggle" for="website"></label>
                  </li>
                  <li class="toggle__container">
                    <label class="toggle__text-label" for="bookmarks">
                      Open <b>Bookmarks/History</b> results in a new tab
                    </label>
                    <input
                      class="toggle__input"
                      id="bookmarks"
                      onChange={(e) => saveGeneralSetting(e, "bookmarkSetting")}
                      defaultChecked={
                        localStorage.bookmarkSetting === "true" ? true : false
                      }
                      type="checkbox"
                    />
                    <label class="toggle" for="bookmarks"></label>
                  </li>
                </ul>
              </div>
              <div class="large-12 columns">
                <h4 class="text-lg">Wallpaper</h4>
                <ul>
                  <li>
                    <div class="radios">
                      <input
                        type="radio"
                        name="wallpaper"
                        id="local-wallpaper"
                        value="local-wallpaper"
                        class="radio"
                        defaultChecked={wallSetting === "local" ? true : false}
                        onChange={(e) => changeWallpaper("local")}
                        hidden
                      />
                      <label for="local-wallpaper"></label>
                      <label for="local-wallpaper">Use local wallpaper</label>
                    </div>
                    <div
                      class="file-btn"
                      disabled={wallSetting === "local" ? false : true}
                    >
                      Choose a local wallpaper
                      <input
                        disabled={wallSetting === "local" ? "" : "disabled"}
                        type="file"
                        id="selectWallpaper"
                        onChange={loadLocalWallpaper}
                      />
                    </div>
                  </li>
                  <li>
                    <div class="radios">
                      <input
                        type="radio"
                        name="wallpaper"
                        id="random-wallpaper"
                        class="radio"
                        value="random-wallpaper"
                        defaultChecked={wallSetting === "random" ? true : false}
                        hidden
                      />
                      <label for="random-wallpaper"></label>
                      <label for="random-wallpaper">Randomize wallpaper</label>
                    </div>
                    <p>
                      Click on the windmill on the left bottom corner of the
                      browser to get a new wallpaper.
                    </p>
                  </li>
                  {/* <li>
                    <div class="radios">
                      <input
                        type="radio"
                        name="wallpaper"
                        id="folder-wallpaper"
                        class="radio"
                        value="folder-wallpaper"
                        defaultChecked={wallSetting === 'folder' ? true : false}
                        hidden
                      />
                      <label for="folder-wallpaper"></label>
                      <label for="folder-wallpaper">
                        Automatically change wallpaper
                      </label>
                    </div>
                    <div class="file-btn" disabled>
                      Choose a folder
                      <input
                        disabled
                        type="file"
                        id="selectWallpaperFolder"
                        webkitdirectory
                        multiple
                        onChange={loadFolderWallpaper}
                      />
                    </div>
                  </li> */}
                  <li>
                    <div class="radios">
                      <input
                        type="radio"
                        name="wallpaper"
                        id="bing-wallpaper"
                        class="radio"
                        value="bing-wallpaper"
                        defaultChecked={wallSetting === "bing" ? true : false}
                        onChange={(e) => changeWallpaper("bing")}
                        hidden
                      />
                      <label for="bing-wallpaper"></label>
                      <label for="bing-wallpaper">Sync Bing wallpaper</label>
                    </div>
                  </li>
                </ul>
                <button
                  id="defaultWallpaper"
                  onClick={(e) => {
                    localStorage.setItem("wallpaperSetting", "random");
                    localStorage.setItem("wallpaper", BackgroundImg);
                    document.body.style.backgroundImage = `url(${BackgroundImg})`;
                  }}
                >
                  Revert to default wallpaper
                </button>
              </div>
              <div class="large-12 columns">
                <h4 class="text-lg">News source</h4>
                <ul>
                  <li>
                    <label for="">Source</label>
                    <select
                      onChange={props.handleFeedChange}
                      id="settings-stories-source"
                      value={selectedFeed}
                    >
                      <option value="yahoo">Yahoo News</option>
                      <option value="hackernoon">Hackernoon</option>
                      <option value="worldpress">Worldpress</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab__pane" id="add">
            <div class="row collapse">
              <h2 class=" text-2xl">Add Links</h2>
              <div class="large-12 columns">
                <h4 class=" text-lg">Shortcuts</h4>
                <div>
                  <form action="#" id="add-shortcuts-form">
                    <ul>
                      <li>
                        <div class="form-row">
                          <label class=" text-lg" for="">
                            Name
                          </label>
                          <input type="text" id="" name="title" />
                        </div>
                      </li>
                      <li>
                        <div class="form-row">
                          <label class=" text-lg" for="">
                            URL
                          </label>
                          <input type="text" id="" name="link" />
                        </div>
                      </li>
                      <li>
                        <div class="form-row">
                          <label class=" text-lg" for="">
                            Icon
                          </label>
                          <div class="file-btn">
                            Choose an icon
                            <input
                              type="file"
                              accept="image/*"
                              name="thumbnail"
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <button id="add-new-shortcut-button">Save</button>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <div class="row collapse">
              <div class="shortcuts-list"></div>
              <div id="ShortcutListContainer"></div>
            </div>
          </div>
        </div>
      </nav>
      <label
        class="be-overlay"
        for="be-toggle-left"
        data-overlay="left"
      ></label>
    </div>
  );
};
export default Sidebar;
