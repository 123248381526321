import { useLocalStorage } from './useLocalStorage'
import moment from 'moment-timezone'

const defaultSetting = () => {
  const timezone = moment.tz.guess()

  return {
    timezone,
    clocks: [
      {
        place: 'Tokyo',
        timezone: 'Asia/Tokyo',
        title: 'Tokyo'
      },
      {
        place: "London",
        timezone: "Europe/London",
        title: "London",
      },
      {
        place: "New York",
        timezone: "America/New_York",
        title: 'New York'
      },
      {
        place: "Singapore",
        timezone: "Asia/Singapore",
        title: 'Singapore'
      },
    ]
  }
}
export const useClockSettings = () => {
  const [settings, setSettings] = useLocalStorage('_clock_settings', defaultSetting())

  return [settings, setSettings];
}