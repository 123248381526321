import { h, render } from "preact";
import { useEffect } from "preact/hooks";
import { useAsync } from "../../hooks/useAsync";
import Icon from "../Icons/RefreshIcon";
import { DEFAULT_WALLPAPER } from "../../helpers";
import ApiService from "../../js/ApiService";

const WallpaperButton = ({ setWallpaper, wallpaperLoaded, imageInfo }) => {
  const { data, status, error, run } = useAsync({
    status: "idle",
  });

  function changeWallpaper() {
    run(ApiService.get("wallpaper"));
  }

  useEffect(() => {
    if (status === "resolved") {
      setWallpaper(data.data);
    }
  }, [status, data]);

  return (
    <div className="flex absolute right-4 bottom-4 cursor-pointer">
      <p className="mr-4 self-center shadow-xl text-blue-100 text-sm">
        Photo by{" "}
        <a
          className="text-blue-500"
          target="_blank"
          href={
            "https://unsplash.com/@" +
            imageInfo.user.username +
            "?utm_source=im-extension-kryopage&utm_medium=referral"
          }
        >
          {imageInfo.user.first_name + " " + imageInfo.user.last_name}
        </a>{" "}
        on{" "}
        <a
          target="_blank"
          className="text-blue-500"
          href="https://unsplash.com/?utm_source=im-extension-kryopage&utm_medium=referral"
        >
          Unsplash
        </a>{" "}
      </p>
      <div onClick={changeWallpaper}>
        <Icon
          className={
            status === "pending" || !wallpaperLoaded
              ? "p-2 animate-spin opacity-80 active:bg-red-700 align-middle bg-red-400 center flex focus:outline-none font-semibold h-12 items-center justify-center justify-items-center shadow-md text-white w-12 rounded-full hover:shadow-lg cursor-pointer"
              : "p-2 opacity-80 active:bg-red-700 align-middle bg-red-400 center flex focus:outline-none font-semibold h-12 items-center justify-center justify-items-center shadow-md text-white w-12 rounded-full hover:shadow-lg cursor-pointer"
          }
          alt="background"
        />
      </div>
    </div>
  );
};

export default WallpaperButton;
