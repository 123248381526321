import { h, render, Component } from "preact";
import { useState } from "preact/hooks";

const Tab = ({ activeTab, label, onClick }) => {
  let className = "tab-list-item";

  if (activeTab === label) {
    className += " tab-list-active";
  }

  return (
    <li className={className} onClick={onClick}>
      {label}
    </li>
  );
};

export default Tab;
