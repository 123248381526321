import { h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";

import Container from "../components/Container";
import DateTime from "../components/DateTime";
import Search from "../components/Search";
import Settings from "../components/Settings";
import Sidebar from "../components/Sidebar";
import WorldClockWidget from "../components/ClockWidget/WorldClockWidget";
import Logo from "../images/kryopage.png";
import WidgetNavbar from "../components/WidgetNavbar";
import { DEFAULT_WALLPAPER, DEFAULT_IMAGE_INFO } from "../helpers";
import { useLocalStorage } from "../hooks/useLocalStorage";
import WallpaperButton from "../components/WallpaperButton/WallpaperButton";
import FooterLine from "../components/Footer/FooterLine";

const App = () => {
  const [imageInfo, setImageInfo] = useLocalStorage(
    "_image_info",
    DEFAULT_IMAGE_INFO
  );
  const [wallpaper, setWallpaper] = useLocalStorage(
    "_wallpaper_src",
    imageInfo.urls.full
  );
  const [wallpaperReady, setWallpaperReady] = useState(false);
  const [prevWallpaper, setPrevWallpaper] = useState(() => wallpaper);
  const [blueGreen, setBlueGreen] = useState("blue");

  const onWallpaperLoad = useCallback(
    (e) => {
      setBlueGreen(blueGreen == "blue" ? "green" : "blue");
      setWallpaperReady(true);
    },
    [wallpaper]
  );

  const setWallpaperCb = useCallback(
    (data) => {
      setImageInfo(data);
      setWallpaper(data.urls.full);
      setPrevWallpaper(wallpaper);
      setWallpaperReady(false);
    },
    [wallpaper, setWallpaper, setPrevWallpaper, setWallpaperReady]
  );

  return (
    <div>
      <div className="blue-green absolute h-screen w-screen top-0 left-0">
        <img src={wallpaper} alt="" class="hidden" onLoad={onWallpaperLoad} />
        <div
          className={
            blueGreen == "blue"
              ? "absolute top-0 left-0 h-full w-full transition duration-1000 ease-linear opacity-1"
              : "absolute top-0 left-0 h-full w-full transition duration-1000 ease-linear opacity-0"
          }
          style={{
            backgroundImage: `url(${
              blueGreen == "blue" && wallpaperReady ? wallpaper : prevWallpaper
            })`,
            backgroundSize: "cover",
          }}
        ></div>
        <div
          className={
            blueGreen == "green"
              ? "absolute top-0 left-0 h-full w-full transition duration-1000 ease-linear opacity-1"
              : "absolute top-0 left-0 h-full w-full transition duration-1000 ease-linear opacity-0 "
          }
          style={{
            backgroundImage: `url(${
              blueGreen == "green" && wallpaperReady ? wallpaper : prevWallpaper
            })`,
            backgroundSize: "cover",
          }}
        ></div>
      </div>

      <Container>
        <div class="flex items-center page-root flex-col h-screen relative pt-16">
          <div class="logo-container my-3">
            <img class="w-24" src={Logo} alt="increditab" />
          </div>
          <div class="mt-5">
            <DateTime></DateTime>
          </div>
          <div class="absolute bottom-3 left-3 w-1/4 p-2">
            <WorldClockWidget class="w-4/12"></WorldClockWidget>
          </div>
          <div class="w-screen">
            <Search class="w-8/12" />
          </div>
          {/* <Sidebar handleFeedChange={() => {}} /> */}
          {/* <Settings open={false}></Settings> */}
        </div>
        <WidgetNavbar></WidgetNavbar>
        <WallpaperButton
          imageInfo={imageInfo}
          setWallpaper={setWallpaperCb}
          wallpaperLoaded={wallpaperReady}
        ></WallpaperButton>
      <FooterLine></FooterLine>
      </Container>
    </div>
  );
};

export default App;
