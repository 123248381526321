import { h, render, Component } from "preact";
import { useEffect, useState } from "preact/hooks";
import moment from "moment";

const DateTime = (props) => {
  const [localTime, setLocalTime] = useState(() => {
    return moment();
  });

  useEffect(() => {
    const id = setInterval(() => {
      setLocalTime(moment());
    }, 60000);
    return () => {
      clearInterval(id);
    };
  }, []);
  return (
    <div class="flex flex-col items-center justify-center mb-2">
      <span class="text-6xl text-white">{localTime.format("HH:mm")}</span>
      <span class="text-white text-xl">
        {localTime.format("ddd")},{localTime.format("MMM D YYYY")}
      </span>
    </div>
  );
};

export default DateTime;
