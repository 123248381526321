import { useEffect, useMemo, useState } from 'preact/hooks'

export const useVisitHistory = ({ filterFn, count = 10 }) => {
  const [history, setHistory] = useState([])

  const filtered = useMemo(() => {
    return history.filter(filterFn).slice(0, count)
  }, [history, filterFn])
  
  useEffect(() => {
    chrome.history.search({ text: "", maxResults: 100 }, function (data) {
      setHistory(data);
    });
  }, [])

  return { filtered, history }
}