import { h, render, Component, Fragment } from "preact";

const Card = ({ heading, subheading, children }) => {
  return (
    <div class="bg-white shadow overflow-hidden sm:rounded-lg w-96">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">{heading}</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">{subheading}</p>
      </div>
      <div class="border-t border-gray-200">{children}</div>
    </div>
  );
};

export default Card;
