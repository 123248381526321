import { h, render, Component, Fragment } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import SearchIcon from "@heroicons/react/outline/SearchIcon";
import { useLocalStorage } from "../hooks/useLocalStorage";
import Api from "../js/ApiService";

const Twitter = (props) => {
  const [loading, setLoading] = useState(true);
  const [tweets, setTweets] = useState([]);
  const [screenName, setScreenName] = useLocalStorage(
    "_twitter_screen_name",
    "twitter"
  );
  const fetchTweets = async () => {
    setLoading(true);
    const response = await Api.get("twitter/timeline", null, {
      screen_name: screenName,
    });
    setTweets(response instanceof Array ? response : []);
    setLoading(false);
  };
  const [screenNameInput, setScreenNameInput] = useState("");
  const onSubmit = (e) => {
    setScreenName(screenNameInput);
    fetchTweets();
  };
  const onScreenNameInput = (e) => {
    setScreenNameInput(e.target.value);
  };
  const onKeyDown = useCallback(
    (e) => {
      if (e.key !== "Enter") return;
      setScreenName(screenNameInput);
    },
    [screenNameInput, setScreenName]
  );
  useEffect(() => {
    setScreenNameInput(screenName);
  }, []);
  useEffect(() => {
    fetchTweets();
  }, [screenName]);
  return (
    <>
      <div className="flex h-14 mt-4 px-4">
        <input
          class="bg-white border-b border-gray-200 border-l border-t mr-0 p-4 rounded-l-lg text-gray-800 w-5/6"
          placeholder="Twitter username"
          value={screenNameInput}
          onInput={onScreenNameInput}
          onKeyDown={onKeyDown}
        />
        <button
          class="bg-blue-400 border-b border-blue-500 border-r border-t font-bold rounded-r-lg text-gray-800 uppercase w-1/6"
          onClick={onSubmit}
        >
          <SearchIcon className="h-10 m-auto text-white w-10" />
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      ) : (
        tweets.map((tweet) => (
          <div class="border-green-100-200 flex items-center justify-center px-5 py-5">
            <a
              href={
                "https://twitter.com/" +
                tweet.user.screen_name +
                "/status/" +
                tweet.id_str
              }
              class="w-full mx-auto rounded-lg bg-white shadow p-5 text-gray-800"
              style="max-width: 400px"
            >
              <div class="w-full flex mb-4">
                <div class="overflow-hidden rounded-full w-12 h-12">
                  <a href={"https://twitter.com/" + tweet.screen_name}></a>
                  <img src={tweet.user.profile_image_url_https} alt="" />
                </div>
                <a href={"https://twitter.com/" + screenName}>
                  <div class="flex-grow pl-3">
                    <h6 class="font-bold text-md">{tweet.user.name}</h6>
                    <p class="text-xs text-gray-600">
                      @{tweet.user.screen_name}
                    </p>
                  </div>
                </a>
                <div class="w-12 text-right">
                  <i class="mdi mdi-twitter text-blue-400 text-3xl"></i>
                </div>
              </div>
              <div class="w-full mb-4">
                <p class="text-sm">{tweet.text}</p>
              </div>
              <div class="w-full">
                <p class="text-xs text-gray-500 text-right">
                  {/* Oct 15th 8:33pm */}
                  {tweet.created_at.slice(0, -10)}
                </p>
              </div>
            </a>
          </div>
        ))
      )}
    </>
  );
};

export default Twitter;
