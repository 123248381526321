import { h, Fragment } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { TranslateIcon } from "@heroicons/react/outline/TranslateIcon";
import { fuzzySearch } from "react-select-search";
import SelectSearch from "react-select-search";

import Container from "./Container";
import Api from "../js/ApiService";
import { useLocalStorage } from "../hooks/useLocalStorage";

const Translate = (props) => {
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useLocalStorage("translation_langauges", [
    { label: "Afrikaans", value: "af" },
    { label: "Albanian", value: "sq" },
    { label: "Amharic", value: "am" },
    { label: "Arabic", value: "ar" },
    { label: "Armenian", value: "hy" },
    { label: "Azerbaijani", value: "az" },
    { label: "Basque", value: "eu" },
    { label: "Belarusian", value: "be" },
    { label: "Bengali", value: "bn" },
    { label: "Bosnian", value: "bs" },
    { label: "Bulgarian", value: "bg" },
    { label: "Catalan", value: "ca" },
    { label: "Cebuano", value: "ceb" },
    { label: "Chinese(Simplified)", value: "	zh-CN" },
    { label: "Chinese(Traditional)", value: "	zh-TW" },
    { label: "Corsican", value: "co" },
    { label: "Croatian", value: "hr" },
    { label: "Czech", value: "cs" },
    { label: "Danish", value: "da" },
    { label: "Dutch", value: "nl" },
    { label: "English", value: "en" },
    { label: "Esperanto", value: "eo" },
    { label: "Estonian", value: "et" },
    { label: "Finnish", value: "fi" },
    { label: "French", value: "fr" },
    { label: "Frisian", value: "fy" },
    { label: "Galician", value: "gl" },
    { label: "Georgian", value: "ka" },
    { label: "German", value: "de" },
    { label: "Greek", value: "el" },
    { label: "Gujarati", value: "gu" },
    { label: "Haitian", value: "Creole	ht" },
    { label: "Hausa", value: "ha" },
    { label: "Hawaiian (ISO-639-2)", value: "haw" },
    { label: "Hebrew", value: "he" },
    { label: "Hindi", value: "hi" },
    { label: "Hmong (ISO-639-2)", value: "hmn" },
    { label: "Hungarian", value: "hu" },
    { label: "Icelandic", value: "is" },
    { label: "Igbo", value: "ig" },
    { label: "Indonesian", value: "id" },
    { label: "Irish", value: "ga" },
    { label: "Italian", value: "it" },
    { label: "Japanese", value: "ja" },
    { label: "Javanese", value: "jv" },
    { label: "Kannada", value: "kn" },
    { label: "Kazakh", value: "kk" },
    { label: "Khmer", value: "km" },
    { label: "Kinyarwanda", value: "rw" },
    { label: "Korean", value: "ko" },
    { label: "Kurdish", value: "ku" },
    { label: "Kyrgyz", value: "ky" },
    { label: "Lao", value: "lo" },
    { label: "Latin", value: "la" },
    { label: "Latvian", value: "lv" },
    { label: "Lithuanian", value: "lt" },
    { label: "Luxembourgish", value: "lb" },
    { label: "Macedonian", value: "mk" },
    { label: "Malagasy", value: "mg" },
    { label: "Malay", value: "ms" },
    { label: "Malayalam", value: "ml" },
    { label: "Maltese", value: "mt" },
    { label: "Maori", value: "mi" },
    { label: "Marathi", value: "mr" },
    { label: "Mongolian", value: "mn" },
    { label: "Myanmar(Burmese)", value: "my" },
    { label: "Nepali", value: "ne" },
    { label: "Norwegian", value: "no" },
    { label: "Nyanja(Chichewa)", value: "ny" },
    { label: "Odia(Oriya)", value: "or" },
    { label: "Pashto", value: "ps" },
    { label: "Persian", value: "fa" },
    { label: "Polish", value: "pl" },
    { label: "Portuguese(Portugal, Brazil)", value: "pt" },
    { label: "Punjabi", value: "pa" },
    { label: "Romanian", value: "ro" },
    { label: "Russian", value: "ru" },
    { label: "Samoan", value: "sm" },
    { label: "Scots(Gaelic)", value: "gd" },
    { label: "Serbian", value: "sr" },
    { label: "Sesotho", value: "st" },
    { label: "Shona", value: "sn" },
    { label: "Sindhi", value: "sd" },
    { label: "Sinhala(Sinhalese)", value: "si" },
    { label: "Slovak", value: "sk" },
    { label: "Slovenian", value: "sl" },
    { label: "Somali", value: "so" },
    { label: "Spanish", value: "es" },
    { label: "Sundanese", value: "su" },
    { label: "Swahili", value: "sw" },
    { label: "Swedish", value: "sv" },
    { label: "Tagalog(Filipino)", value: "tl" },
    { label: "Tajik", value: "tg" },
    { label: "Tamil", value: "ta" },
    { label: "Tatar", value: "tt" },
    { label: "Telugu", value: "te" },
    { label: "Thai", value: "th" },
    { label: "Turkish", value: "tr" },
    { label: "Turkmen", value: "tk" },
    { label: "Ukrainian", value: "uk" },
    { label: "Urdu", value: "ur" },
    { label: "Uyghur", value: "ug" },
    { label: "Uzbek", value: "uz" },
    { label: "Vietnamese", value: "vi" },
    { label: "Welsh", value: "cy" },
    { label: "Xhosa", value: "xh" },
    { label: "Yiddish", value: "yi" },
    { label: "Yoruba", value: "yo" },
    { label: "Zulu", value: "zu" },
  ]);
  const [value, setValue] = useState("");
  const [target, setTarget] = useState(languages.find((l) => l.code === "en"));
  const [translated, setTranslated] = useState("");
  const translate = useCallback(async () => {
    setLoading(true);
    const response = await Api.post("translate", null, { q: value, target });
    setTranslated(response.data.translations[0].translatedText);
    setLoading(false);
  }, [value, target]);

  return (
    <Container className="p-4">
      <div class="flex-1">
        <div class="w-full inline-flex">
          <input
            type="text"
            value={value}
            onInput={(e) => setValue(e.target.value)}
            placeholder="Enter text to translate"
            class="w-full rounded-md text-gray-800 bg-gray-100 focus:bg-white 
                  focus:outline-none focus:shadow-lg py-3 px-10 max-w-xl text-md focus:text-gray-800"
          />
          <svg
            class="h-5 mt-2.5 px-1 absolute text-gray-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <div class="w-full my-5 justify-between">
          <select
            class="h-10 rounded w-full"
            onChange={(e) => setTarget(e.target.value)}
          >
            {languages.map((lang, i) => (
              <option value={lang.value} key={i}>
                {lang.label}
              </option>
            ))}
          </select>
        </div>
        <div class="w-full inline-flex my-5 justify-between">
          <button
            class="flex-shrink-0 bg-blue-600 text-white text-base font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-200"
            onClick={(e) => translate()}
          >
            Translate
          </button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        ) : (
          translated &&
          translated.length && (
            <div class="h-24 inline-flex justify-self-end rounded w-full">
              <p class="h-full w-full rounded-md text-gray-800 bg-gray-100  py-3 px-3 max-w-xl text-md">
                {translated}
              </p>
            </div>
          )
        )}
      </div>
    </Container>
  );
};

export default Translate;
