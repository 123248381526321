import { h, render, Component, Fragment } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import BookmarkIcon from "@heroicons/react/outline/BookmarkIcon";
import { AiOutlineTwitter } from "react-icons/ai";
import TranslateIcon from "./Icons/TranslateIcon";
import HistoryIcon from "./Icons/HistoryIcon";

import Card from "./Card";
import Container from "./Container";
import History from "./History";
import Bookmarks from "./Bookmarks";
import Translate from "./Translate";
import WidgetLayout from "./WidgetLayout";
import WidgetToggler from "./WidgetToggler";
import Twitter from "./Twitter";

const WidgetNavbar = () => {
  return (
    <Container class="absolute right-0 top-0 w-20 backdrop-filter h-screen  px-3 py-3">
      <WidgetToggler
        icon={<TranslateIcon class="h-8 self-center w-8" />}
        widgetTitle="Translate"
      >
        <Container class="">
          <Card heading="Translate">
            <div className="h-96 overflow-y-auto scrollable scrollable-y">
              <Translate />
            </div>
          </Card>
        </Container>
      </WidgetToggler>
      <WidgetToggler
        // icon={<TwitterIcon class=" text-white h-8 self-center w-8" />}
        icon={<AiOutlineTwitter class=" text-white h-8 self-center w-8" />}
        widgetTitle="Twitter"
      >
        <Container class="">
          <Card heading="Twitter">
            <div className="h-96 overflow-y-auto scrollable scrollable-y">
              <Twitter></Twitter>
            </div>
          </Card>
        </Container>
      </WidgetToggler>
      {/* <WidgetToggler
        icon={<HistoryIcon class="h-8 self-center w-8" />}
        widgetTitle="History"
      >
        <Container class="">
          <Card heading="History">
            <div className="h-96 overflow-y-auto scrollable scrollable-y">
              <History></History>
            </div>
          </Card>
        </Container>
      </WidgetToggler> */}
      {/* <WidgetToggler
        icon={<BookmarkIcon class="h-8 self-center w-8" />}
        widgetTitle="Bookmarks"
      >
        <Container class="">
          <Card heading="Bookmarks">
            <div className="h-96 overflow-y-auto scrollable scrollable-y">
              <Bookmarks></Bookmarks>
            </div>
          </Card>
        </Container>
      </WidgetToggler> */}
    </Container>
  );
};

export default WidgetNavbar;
