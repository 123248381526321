import { h, render, Component, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";

import { useBookmarks } from "../hooks/useBookmarks";

const Bookmarks = (props) => {
  const filterFn = (d) => {
    const regex = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return regex.test(d.url);
  };
  const { filtered, bookmarks } = useBookmarks({ filterFn, count: 500 });

  return (
    <div class="flex flex-col h-96 p-4">
      {filtered.map((bookmark) => {
        let url = bookmark.url
        if (url && url.length) {
          url = (new URL(url)).origin
        }

        return (
          <a
            class="flex h-24 px-1 py-1 items-center text-lg cursor-pointer mb-4 group rounded-lg hover:border-blue-500 border-2 border-transparent"
            href={bookmark.url}
          >
            <img
              src={`http://www.google.com/s2/favicons?domain=${url}`}
              alt={bookmark.url}
              class="h-4 w-4"
            />
            <span class="overflow-ellipsis w- ml-5 group-hover:text-blue-500">
              {bookmark.title.slice(0, 50)}
            </span>
          </a>
        );
      })}
    </div>
  );
};

export default Bookmarks;
