import { h, render, Fragment } from "preact";
import { useCallback, useRef, useState } from "preact/hooks";
import { useClickAway } from "../hooks/useClickAway";

import Container from "./Container";

const WidgetToggler = ({ children, icon, widgetTitle }) => {
  const [showWidget, setShowWidget] = useState(false);

  const widgetRef = useRef();
  const onToggleClick = () => {
    setShowWidget((s) => !s);
  };

  const clickAwayCallback = useCallback(() => setShowWidget(false));
  useClickAway(widgetRef, clickAwayCallback);

  return (
    <>
      <div class="relative" ref={widgetRef}>
        <div
          class="group active:bg-red-700 align-middle bg-red-400 center flex focus:outline-none font-semibold h-12 items-center justify-center justify-items-center mb-3 shadow-md text-white w-12 rounded-full hover:shadow-lg cursor-pointer"
          onClick={onToggleClick}
        >
          <div className="absolute bg-gray-500 opacity-0 rounded-md group-hover:opacity-100 px-2 py-1 right-16 top-1/4">
            {widgetTitle}
          </div>
          {icon}
        </div>
        {
          <Container
            class={
              showWidget
                ? "absolute container right-16 top-0"
                : "absolute hidden"
            }
          >
            {children}
          </Container>
        }
      </div>
    </>
  );
};

export default WidgetToggler;
