import GoogleLogo from "../images/google-icon.png";
import YahooLogo from "../images/yahoo-icon.png";
import BingLogo from "../images/bing-icon.png";
import DuckduckgoLogo from "../images/duckduckgo-icon.png";
import BaiduLogo from "../images/baidu-icon.png";
import DefaultWallpaper from "../images/wallpaper-default.jpg";
export const DEFAULT_IMAGE_INFO = {
  id: "_6soQim3P_w",
  created_at: "2017-06-30T17:01:56-04:00",
  updated_at: "2021-07-22T01:02:03-04:00",
  promoted_at: "2017-07-01T04:50:24-04:00",
  width: 4000,
  height: 3000,
  color: "#a6c0d9",
  blur_hash: "LsE3*q8^RPxu.9IUV@t6RPozoff6",
  description: "Surf wave inside view",
  alt_description: "rippling body of water waves",
  urls: {
    raw: "https://images.unsplash.com/photo-1498856460360-fa62baa95582?ixid=MnwyMjg3ODZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjcwMTA5MzQ&ixlib=rb-1.2.1",
    full: "https://images.unsplash.com/photo-1498856460360-fa62baa95582?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyMjg3ODZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjcwMTA5MzQ&ixlib=rb-1.2.1&q=85",
    regular:
      "https://images.unsplash.com/photo-1498856460360-fa62baa95582?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjg3ODZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjcwMTA5MzQ&ixlib=rb-1.2.1&q=80&w=1080",
    small:
      "https://images.unsplash.com/photo-1498856460360-fa62baa95582?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjg3ODZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjcwMTA5MzQ&ixlib=rb-1.2.1&q=80&w=400",
    thumb:
      "https://images.unsplash.com/photo-1498856460360-fa62baa95582?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjg3ODZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjcwMTA5MzQ&ixlib=rb-1.2.1&q=80&w=200",
  },
  links: {
    self: "https://api.unsplash.com/photos/_6soQim3P_w",
    html: "https://unsplash.com/photos/_6soQim3P_w",
    download: "https://unsplash.com/photos/_6soQim3P_w/download",
    download_location:
      "https://api.unsplash.com/photos/_6soQim3P_w/download?ixid=MnwyMjg3ODZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjcwMTA5MzQ",
  },
  categories: [],
  likes: 281,
  liked_by_user: false,
  current_user_collections: [],
  sponsorship: null,
  user: {
    id: "fbPZwdKgWWs",
    updated_at: "2021-07-22T22:51:07-04:00",
    username: "jeremybishop",
    name: "Jeremy Bishop",
    first_name: "Jeremy",
    last_name: "Bishop",
    twitter_username: null,
    portfolio_url: "https://www.jeremybishopphotography.com",
    bio: "I love supporting and inspiring creatives around the world.\r\nMy passion is the ocean and water photography, and I am striving to make an impact to save our Oceans and our Reefs!   https://www.paypal.com/paypalme/JeremyBishopPhoto ",
    location: "California",
    links: {
      self: "https://api.unsplash.com/users/jeremybishop",
      html: "https://unsplash.com/@jeremybishop",
      photos: "https://api.unsplash.com/users/jeremybishop/photos",
      likes: "https://api.unsplash.com/users/jeremybishop/likes",
      portfolio: "https://api.unsplash.com/users/jeremybishop/portfolio",
      following: "https://api.unsplash.com/users/jeremybishop/following",
      followers: "https://api.unsplash.com/users/jeremybishop/followers",
    },
    profile_image: {
      small:
        "https://images.unsplash.com/profile-1610519305252-c9d552283aaaimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
      medium:
        "https://images.unsplash.com/profile-1610519305252-c9d552283aaaimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
      large:
        "https://images.unsplash.com/profile-1610519305252-c9d552283aaaimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128",
    },
    instagram_username: "bluumind",
    total_collections: 19,
    total_likes: 3675,
    total_photos: 904,
    accepted_tos: true,
    for_hire: true,
    social: {
      instagram_username: "bluumind",
      portfolio_url: "https://www.jeremybishopphotography.com",
      twitter_username: null,
      paypal_email: null,
    },
  },
  exif: {
    make: "GoPro",
    model: "HERO4 Silver",
    exposure_time: "1/2300",
    aperture: "2.8",
    focal_length: "3.0",
    iso: 100,
  },
  location: {
    title: "Newport Beach, United States",
    name: "Newport Beach, United States",
    city: "Newport Beach",
    country: "United States",
    position: { latitude: 33.6188829, longitude: -117.9298493 },
  },
  views: 2112610,
  downloads: 10933,
};
export const DEFAULT_WALLPAPER = DefaultWallpaper;
export const TIMEZONES = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg'
];

export const searchEngines = [
  {
    name: "Google",
    link: "https://google.com/search?q=",
    imagesLink: "https://google.com/search?tbm=isch&q=",
    videosLink: "https://google.com/search?tbm=vid&q=",
    newsLink: "https://google.com/search?tbm=nws&q=",
    icon: GoogleLogo,
  },
  {
    name: "Yahoo",
    link: "https://search.yahoo.com/search?p=",
    imagesLink: "https://images.search.yahoo.com/search/images?p=",
    videosLink: "https://video.search.yahoo.com/search/video;?p=",
    newsLink: "https://news.search.yahoo.com/search?p=",
    icon: YahooLogo,
  },
  {
    name: "Bing",
    link: "https://www.bing.com/search?q=",
    imagesLink: "https://www.bing.com/images/search?q=",
    videosLink: "https://www.bing.com/videos/search?q=",
    newsLink: "https://www.bing.com/news/search?q=",
    icon: BingLogo,
  },
  {
    name: "Duckduckgo",
    link: "https://duckduckgo.com/?q=",
    imagesLink: "https://duckduckgo.com/?iar=images&iax=images&ia=images&q=",
    videosLink: "https://duckduckgo.com/?iax=videos&ia=videos&q=",
    newsLink: "https://duckduckgo.com/?iar=news&ia=news&q=",
    icon: DuckduckgoLogo,
  },
  {
    name: "Baidu",
    link: "https://www.baidu.com/s?ie=utf-8&f=8&rsv_bp=1&rsv_idx=1&tn=baidu&wd=",
    imagesLink:
      "https://image.baidu.com/search/index?tn=baiduimage&ps=1&ct=201326592&lm=-1&cl=2&nc=1&ie=utf-8&ie=utf-8&word=",
    videosLink:
      "https://www.baidu.com/sf/vsearch?pd=video&tn=vsearch&ie=utf-8&wd=",
    newsLink:
      "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=",
    icon: BaiduLogo,
  },
];
