import { h, render, Component, Fragment } from "preact";
import { useEffect } from "preact/hooks";

import { useModal } from "../hooks/useModal";
const Modal = ({
  header,
  footer,
  onClose = () => {},
  onSubmit = () => {},
  showModal,
  children,
}) => {
  const [show, setShow] = useModal(false);
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <>
      {show && (
        <div class="main-modal fixed w-full h-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster bg-gradient-to-r from-blue-500">
          <div class="border border-teal-500 shadow-lg modal-container bg-white w-11/12 md:max-w-md mx-auto rounded z-50 overflow-y-auto">
            <div class="modal-content py-4 text-left px-6">
              {/* Title */}
              <div class="flex justify-between items-center pb-3">
                <div>{header && header()}</div>
                <div
                  class="modal-close cursor-pointer z-50"
                  onClick={() => onClose(false)}
                >
                  <svg
                    class="fill-current text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                  </svg>
                </div>
              </div>
              {/* Body */}
              <div class="">{children}</div>
              {/* Footer */}
              <div class="flex justify-end pt-2">
                {footer ? (
                  typeof footer === "function" ? (
                    footer()
                  ) : (
                    footer
                  )
                ) : (
                  <>
                    <button
                      class="focus:outline-none modal-close px-4 bg-gray-400 p-3 rounded-lg text-black hover:bg-gray-300"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      class="focus:outline-none px-4 bg-teal-500 p-3 ml-3 rounded-lg text-white hover:bg-teal-400"
                      onClick={onSubmit}
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
