import { h, render, Component, Fragment } from "preact";
import { useEffect, useMemo, useRef, useState } from "preact/hooks";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { useModal } from "../hooks/useModal";
import Tabs from "./Tabs";
import Modal from "./Modal";

const Settings = ({ open = false }) => {
  const [tabs, setTabs] = useState(["General", "Appearance", "Search"]);
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useModal(false);
  // const [wallpaper, setWallpaper] = useLocalStorage("wallpaper", null);

  const onChangeTab = (val) => {
    setCurrentTab(val);
  };

  const onShowModalClick = () => {
    setShowModal(true);
  };

  return (
    <div>
      Settings: {currentTab}
      <button onClick={onChangeTab}>Change</button>
      <button onClick={onShowModalClick}>Show</button>
      {showModal && (
        <Modal
          header={() => <h2>This is header</h2>}
          body={() => {
            return (
              <Tabs defaultActive={0}>
                {tabs.map((tab, index) => (
                  <div label={tab} key={index}>
                    {tab}
                  </div>
                ))}
              </Tabs>
            );
          }}
        ></Modal>
      )}
    </div>
  );
};

export default Settings;
