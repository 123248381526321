import { useEffect, useMemo, useState } from 'preact/hooks'

export const useBookmarks = ({ filterFn, count = 10 }) => {
  const [bookmarks, setBookmarks] = useState([])

  const filtered = useMemo(() => {
    return bookmarks
    // .filter(filterFn).slice(0, count)
  }, [bookmarks])
  const [bmUrls, setBmUrls] = useState([])

  const listBookmarks = (parentNode, bms = []) => {
    parentNode.forEach(function (bookmark) {
      if (!(bookmark.url === undefined || bookmark.url === null)) {
        bms.push(bookmark);
      }
      if (bookmark.children) {
        listBookmarks(bookmark.children, bms);
      }
    });

    return bms
  }

  useEffect(() => {
    const fetchBookmarks = () => {
      chrome.bookmarks.getTree(async (rootNode) => {
        const _bookmarks = await listBookmarks(rootNode);
        setBookmarks(_bookmarks)
      });
    }
    fetchBookmarks()
  }, [])

  return { filtered, bookmarks }
}