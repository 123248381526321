import { h, render, Component } from "preact";
import { useState } from "preact/hooks";
import SearchList from "./SearchList";
import GoogleLogo from "../images/google-icon.png";
import { searchEngines } from "../helpers";
import { useLocalStorage } from "../hooks/useLocalStorage";
import glass from "../images/glass.svg";

const Search = (props) => {
  const [text, setText] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [searchEngine, setSearchEngine] = useLocalStorage(
    "searchEngine",
    searchEngines.find((s) => s.name === "Bing")
  );
  const [logo, setLogo] = useState(searchEngine.icon || GoogleLogo);
  const [active, setActive] = useLocalStorage("searchType", "all");
  const handleShowSearch = () => {
    setShowSearch(!showSearch);
  };
  const getLogo = (searchLogo) => {
    setShowSearch(!showSearch);
    setLogo(searchLogo);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const engine = searchEngine;
      const query = text;
      const searchType = active;
      let link = "";
      debugger
      if (searchType === "all")
        return;

      link = engine[`${searchType}Link`] + query;

      let val = localStorage.searchSetting || "false";
      if (val === "true") window.open(link, "_blank");
      else window.open(link, "_self");
    }
  };
  return (
    <div
      class="mx-auto flex flex-col justify-center items-center w-6/12 relative mb-12 mt-12  "
      id="search-block"
    >
      <form className="flex flex-col w-full gap-2" target="_blank" action="https://search.kryopage.com">
        <input type="hidden" name="id" value="e75d92ea6ce20e395c878cac88b7e32444ef35f1"></input>
        <div class="w-full flex flex-col justify-center">
          <div class="flex text-white px-10 mb-3 text-lg search-terms">
            <span
              onClick={() => {
                setActive("all");
                localStorage.setItem("searchType", "all");
              }}
              class={`${active === "all"
                  ? "font-bold bg-blue-400 rounded-xs mx-3 px-5 pb-1 pt-1"
                  : "mx-3 px-5 pb-1 pt-1"
                } cursor-pointer py-1 rounded-l-full rounded-r-full hover:bg-orange-600`}
            >
              All
            </span>
            <span
              onClick={() => {
                setActive("images");
                localStorage.setItem("searchType", "images");
              }}
              class={`${active === "images"
                  ? "font-bold bg-blue-400 rounded-xs mx-3 px-5 pb-1 pt-1"
                  : "mx-3 px-5 pb-1 pt-1"
                } cursor-pointer px-4 py-1 rounded-l-full rounded-r-full hover:bg-orange-500`}
            >
              Images
            </span>
            <span
              onClick={() => {
                setActive("videos");
                localStorage.setItem("searchType", "videos");
              }}
              class={`${active === "videos"
                  ? "font-bold bg-blue-400 rounded-xs mx-3 px-5 pb-1 pt-1"
                  : "mx-3 px-5 pb-1 pt-1"
                } cursor-pointer px-4 py-1 rounded-l-full rounded-r-full hover:bg-orange-500`}
            >
              Videos
            </span>
            <span
              onClick={() => {
                setActive("news");
                localStorage.setItem("searchType", "news");
              }}
              class={`${active === "news"
                  ? "font-bold bg-blue-400 rounded-xs mx-3 px-5 pb-1 pt-1"
                  : "mx-3 px-5 pb-1 pt-1"
                } cursor-pointer px-4 py-1 rounded-l-full rounded-r-full hover:bg-orange-500`}
            >
              News
            </span>
          </div>
          <div
            class="flex justify-center mx-4 my-auto field relative"
            id="searchform"
          >
            <input
              class="block border-2 border-white flex-1 focus:ring-2 focus:ring-blue-600 h-16 outline-none p-3 pl-16 placeholder-gray-400 placeholder-opacity-75 rounded-xl text-xl"
              type="text"
              id="searchterm"
              name="q"
              placeholder="Type something to search..."
              onChange={(e) => {
                e.preventDefault();
                setText(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              value={text}
              autofocus
            />
            <button
              class="absolute bg-white border-gray-300 focus:outline-none h-full pl-3 rounded-full rounded-r-full left-0"
              type="submit"
              id="changeSearch"
             onClick={handleShowSearch}
            >
              <img class=" float-right h-10 m-0" src={glass} id="search-icon" />
            </button>
          </div>
        </div>
        {/* {showSearch && (
        <SearchList searchShow={handleShowSearch} setSearchLogo={getLogo} />
      )} */}
      </form>
    </div>
  );
};
export default Search;
